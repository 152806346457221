import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql, navigate } from "gatsby";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import Layout from "../components/layout";
import config from "../../config";
import { Button, PageHeader } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default class MDXRuntimeTest extends Component {
  render() {
    const { data } = this.props;

    if (!data) {
      return this.props.children;
    }
    const { mdx } = data;

    // meta tags
    let canonicalUrl = config.gatsby.siteUrl;
    canonicalUrl =
      config.gatsby.pathPrefix !== "/"
        ? canonicalUrl + config.gatsby.pathPrefix
        : canonicalUrl;
    canonicalUrl = canonicalUrl + mdx.fields.slug;

    const previousUrl = mdx.fields.slug.substr(
      0,
      mdx.fields.slug.lastIndexOf("/")
    );

    return (
      <Layout
        {...this.props}
        title={mdx.fields.title}
        date={mdx.frontmatter.date}
      >
        <Helmet>
          <title>{mdx.fields.title} | LTA Antistollingszorg</title>
          <meta name="title" content={mdx.fields.title} />
          <meta
            name="description"
            content="De landelijke transmurale afspraak (LTA) antistollingszorg geeft richting aan de samenwerking tussen diverse specialismen. Op deze website vindt u onder meer informatie over het voorschrijven en de follow-up van antitrombotica, periprocedureel beleid en het beleid bij bloedingen. Ook vindt u op deze website informatie over onderwerpen die belangrijk zijn in de communicatie met patiënten. Voor de onderdelen periprocedureel beleid en beleid bij bloedingen zijn beslisbomen ontwikkeld."
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <PageHeader
          backIcon={false}
          title={
            <span
              onClick={() => navigate(previousUrl)}
              style={{ cursor: "pointer" }}
            >
              {mdx.fields.title}
            </span>
          }
          style={{
            borderBottom: "2px solid #ede7f3",
            backgroundColor: "white",
            zIndex: "2000",
            padding: "6px 20px",
          }}
          extra={[
            <Button
              icon={<CloseOutlined />}
              onClick={() => navigate(previousUrl)}
              shape="circle"
              size="large"
              type="primary"
            />,
          ]}
        />
        <div style={{ backgroundColor: "#f0f0f0" }}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        parent
        date
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
